<template>
	<div id="textbook">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>总务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">门禁设备管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item">
				<div class="nav-tab-bar u-f-item">
					<div class="font_14 nav-tab-item" @click="tabTap(index)" :class="index == tabIndex ? 'active' : ''" v-for="(item, index) in meun" :key="index">{{ item }}</div>
				</div>
			</div>
			<div class="content">
				<div class="suguan">
					<div class="title u-f-item u-f-jsb">
						<div>共{{ total }}条数据</div>
						<div class="u-f-item">
							<el-button size="small" @click="openDialog(1)">添加时间段</el-button>
							<!-- <el-button size="small" type="primary" @click="derive">导出</el-button> -->
						</div>
					</div>
					<el-table :data="list" height="65vh" style="width: 100%;border: 1px solid #EEEEEE;">
						<el-table-column type="index" label="序号" align="center"></el-table-column>
						<el-table-column label="规则名称" align="center" prop="name"></el-table-column>
						<el-table-column label="星期" align="center" prop="week"></el-table-column>
						<el-table-column prop="start_time" label="开启时间" align="center"></el-table-column>
						<el-table-column label="关闭时间" prop="end_time" align="center"></el-table-column>
						<el-table-column label="操作" width="150" align="center">
							<template slot-scope="scope">
								<el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
								<el-button @click="delRules(scope.row.id)" type="text" style="color: red;" size="small">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
		</index>

		<!-- 添加 编辑教材-->
		<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="500px" :before-close="handleClose">
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						规则名称
					</div>
					<el-input v-model="name" placeholder="请输入规则名称" style="width: 70%;"></el-input>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						开放时间
					</div>
					<el-time-select v-model="start_time" :picker-options="{
					    start: '05:30',
					    step: '00:15',
					    end: '24:00'
					  }" placeholder="选择时间" style="width: 70%;">
					</el-time-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						关闭时间
					</div>
					<el-time-select v-model="end_time" :picker-options="{
					    start: '05:30',
					    step: '00:15',
					    end: '24:00'
					  }" placeholder="选择时间" style="width: 70%;">
					</el-time-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						开放周期
					</div>
					<el-select v-model="week" placeholder="请选择" style="width: 70%;">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			meun: ['设备管理', '通行时间'],
			tabIndex: 1,
			page: 1,
			limit: 10,
			list: [],
			total: 0,
			VisibleTitle: '添加时间段',
			addVisible: false,
			name: '',
			editId: '',
			options: [
				{ value: 1, label: '星期一' },
				{ value: 2, label: '星期二' },
				{ value: 3, label: '星期三' },
				{ value: 4, label: '星期四' },
				{ value: 5, label: '星期五' },
				{ value: 6, label: '星期六' },
				{ value: 0, label: '星期日' }
			],
			week:'',
			start_time:'',
			end_time:''
		};
	},
	mounted() {
		this.getRulesList();
	},
	methods: {
		tabTap(index) {
			if (index == 0) {
				this.$router.push('/deviceManage');
			}
			this.tabIndex = index;
		},
		getRulesList() {
			this.$api.setting
				.getRulesList({
					page: this.page,
					limit: this.limit
				})
				.then(res => {
					if (res.data.code == 1) {
						this.total = res.data.data.total;
						this.list = res.data.data.rows;
					}
				});
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getRulesList();
		},
		closeVisible() {
			this.init();
			this.addVisible = false;
		},
		init() {
			this.name = '';
			this.start_time = '';
			this.end_time = '';
			this.week = '';
		},
		handleClose(done) {
			done();
		},
		openDialog(type, item) {
			this.type = type;
			if (type == 1) {
				this.VisibleTitle = '添加时间段';
			} else if (type == 2) {
				this.VisibleTitle = '编辑时间段';
				this.name = item.name;
				this.week = item.week;
				this.start_time = item.start_time;
				this.end_time = item.end_time;
				this.editId = item.id;
			}
			this.addVisible = true;
		},
		submit() {
			if (this.type == 1) {
				this.addRules();
			} else if (this.type == 2) {
				this.editDevice();
			}
		},
		// 添加
		addRules() {
			if (!this.name) {
				return this.$message.error('请输入规则名称');
			}
			if (!this.start_time) {
				return this.$message.error('请选择开放时间');
			}
			if (!this.end_time) {
				return this.$message.error('请选择关闭时间');
			}
			if (!this.week) {
				return this.$message.error('请选择周期');
			}
			this.$api.setting
				.addRules({
					name: this.name,
					week: this.week, 
					end_time: this.end_time,
					start_time: this.start_time,
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.init();
						this.getRulesList();
						this.addVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 编辑
		editRules() {
			if (!this.name) {
				return this.$message.error('请输入规则名称');
			}
			if (!this.start_time) {
				return this.$message.error('请选择开放时间');
			}
			if (!this.end_time) {
				return this.$message.error('请选择关闭时间');
			}
			if (!this.week) {
				return this.$message.error('请选择周期');
			}
			this.$api.setting
				.editRules({
					name: this.name,
					week: this.week, 
					end_time: this.end_time,
					start_time: this.start_time,
					id: this.editId
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('编辑成功');
						this.init();
						this.getRulesList();
						this.addVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		delRules(id) {
			this.$confirm('确认要删除该记录吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$api.setting
					.delRules({id: id})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getRulesList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			})
		}
	}
};
</script>

<style lang="scss">
#textbook {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;

		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}

			&div:last-child {
				border-right: none;
			}

			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}

			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}

	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;

		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}

	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;

		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;

			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}

			.add:hover {
				color: #007aff;
			}

			.building-list {
				padding: 25px;
				height: 74vh;
				overflow-y: auto;

				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;

					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}

					.el-checkbox {
						display: block;
					}

					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}

						i:hover {
							color: red;
						}
					}
				}

				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;

					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;

						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}

							i:hover {
								color: red;
							}
						}
					}

					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}

		.right {
			height: 68vh;
			width: 100%;
			padding: 25px 50px;

			.title {
				padding: 0 0 15px 0;
			}
		}

		.suguan {
			padding: 15px 50px 15px 50px;
			height: 70vh;

			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;

		.el-dialog__title {
			font-size: 16px;
		}
	}

	.el-dialog__body {
		padding: 0 20px 35px 20px;

		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;

				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					width: 80px;
					text-align: right;

					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}

				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}

				.input-item {
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}

					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}

					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}

			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}

			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}

			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}

			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}

	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}

.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;

		.el-dialog__title {
			font-size: 16px;
		}
	}

	.el-dialog__body {
		padding: 0;

		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;

				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}

					.active {
						color: #007aff;
						position: relative;
					}

					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}

					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}

			.user-list-content {
				height: 40vh;

				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;

					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}

					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}

				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;

					.list {
						display: flex;
						flex-wrap: wrap;

						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}

						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
	}
		}

	.dialog-title {
		padding: 10px 0 5px 0;
	}

	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}

.el_option {
	display: none !important;
}
</style>
